<template>
	<div class="flex justify-center w-full mx-auto bg-[#FAFAFA] min-h-[316px] lg:min-h-[348px] border-y-2 border-gray-100">
		<CarouselDefault
			id="testimonial"
			class="max-w-4xl"
			slider-classes="space-x-8 divide-x divide-[#D5D5D5] px-4 pb-4"
			:gap-offset="32"
			arrow-visibility="hidden"
			bullet-visibility="hidden md:block"
			bullet-size="small"
			bullet-position-classes="bottom-4 lg:bottom-8"
			bullet-inner-classes="hover:ring-mx-orange-muted hover:ring-offset-[#FAFAFA]"
			:header-text="$t('testimonials-header-text')"
			@scroll-index="setScrollIndex"
		>
			<template #slides>
				<Testimonial
					v-for="testimonial in testimonials"
					:key="$rt(testimonial.id)"
					:testimonial="testimonial"
					:scroll-index="scrollIndex"
					@mouseenter="handleHover($rt(testimonial.id))"
				/>
			</template>
		</CarouselDefault>
	</div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import type { TestimonialProps } from '@/components/testimonial/types'
import type { CarouselSlideInteractionEvent } from '@/types/gtm/events'
import { TESTIMONIAL_HOVER } from '@/constants/gtm/events'
import { useTrackEvent } from '@/composables/useEventTracking'

const { tm } = useI18n({
	useScope: 'global'
})

const testimonials = tm('testimonials') as TestimonialProps[]

const scrollIndex = ref(0)

const setScrollIndex = (index: number) => {
	scrollIndex.value = index
}

function handleHover (id: string) {
	const trackedEvent: CarouselSlideInteractionEvent = {
		event: TESTIMONIAL_HOVER,
		category: 'Carousel',
		action: 'hover',
		label: 'Testimonial Hover',
		carousel_id: 'testimonial',
		slide_index: scrollIndex.value,
		slide_id: id,
		element_id: id
	}
	useTrackEvent(trackedEvent)
}
</script>
