<template>
	<div :id="id">
		<div class="flex flex-col w-64 pl-8 h-44 justify-items-center">
			<div class="w-full">
				<div class="text-sm text-black line-clamp-5">
					{{ $rt(description) }}
				</div>
			</div>
			<div class="w-full mt-4">
				<div class="text-sm text-black line-clamp-1">
					{{ $rt(author) }}
				</div>
			</div>
			<div class="flex items-center self-start w-full space-x-2">
				<IconStar
					v-for="star in 5"
					:key="star"
					:class="[
						rating >= star ? 'text-star-yellow' : 'text-gray-300',
						'flex items-center space-x-1'
					]"
				/>
				<a
					:href="$rt(link)"
					target="_blank"
					class="text-sm font-medium text-mx-orange hover:underline"
					@click="handleClick($rt(id))"
				>
					{{ $t('read-review') }}
				</a>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import { reactive } from 'vue'
import type { TestimonialProps } from '@/components/testimonial/types'
import type { CarouselSlideInteractionEvent } from '@/types/gtm/events'
import { TESTIMONIAL_CLICK } from '@/constants/gtm/events'
import { useTrackEvent } from '@/composables/useEventTracking'

const props = defineProps({
	testimonial: {
		type: Object as PropType<TestimonialProps>,
		required: true
	},
	scrollIndex: {
		type: Number,
		default: 0
	}
})

const { id = '', description = '', author = '', link = '', rating = 0 } = reactive(props.testimonial)
const { scrollIndex } = toRefs(props)

function handleClick (id: string) {
	const trackedEvent: CarouselSlideInteractionEvent = {
		event: TESTIMONIAL_CLICK,
		category: 'Carousel',
		action: 'click',
		label: 'Testimonial Click',
		carousel_id: 'testimonial',
		slide_id: id,
		slide_index: scrollIndex.value,
		element_id: 'anchor'
	}
	useTrackEvent(trackedEvent)
}
</script>
